import { render, staticRenderFns } from "./questionCard.vue?vue&type=template&id=e807821a"
import script from "./questionCard.vue?vue&type=script&lang=js"
export * from "./questionCard.vue?vue&type=script&lang=js"
import style0 from "./questionCard.vue?vue&type=style&index=0&id=e807821a&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports